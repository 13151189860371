@font-face {
    font-family: Jost, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    unicode-range: U+000-5FF;
    src:
        local('Jost Regular'),
        local('Jost-Regular'),
        url('../fonts/Jost-400-Book.woff2') format('woff2'),
        url('../fonts/Jost-400-Book.woff') format('woff');
}

@font-face {
    font-family: Jost, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    unicode-range: U+000-5FF;
    src:
        local('Jost Medium'),
        local('Jost-Medium'),
        url('../fonts/Jost-500-Medium.woff2') format('woff2'),
        url('../fonts/Jost-500-Medium.woff') format('woff');
}

@font-face {
    font-family: Jost, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    unicode-range: U+000-5FF;
    src:
        local('Jost Bold'),
        local('Jost-Bold'),
        url('../fonts/Jost-700-Bold.woff2') format('woff2'),
        url('../fonts/Jost-700-Bold.woff') format('woff');
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    unicode-range: U+000-5FF;
    src:
        local('Roboto Regular'),
        local('Roboto-Regular'),
        url('../fonts/roboto-regular.woff2') format('woff2'),
        url('../fonts/roboto-regular.woff') format('woff');
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
}

body {
    background-color: #f9fafb;
    margin: 0;
    overflow: hidden;
    padding: 0 !important;
}

a {
    text-decoration: none;
}

[contenteditable='true']:empty::before {
    content: attr(placeholder);
    display: block; /* For Firefox */
}

[role='tooltip'] {
    z-index: 10000;
}

svg .logo {
    &.filled,
    & .filled {
        fill: $indigo;
    }
}

.loading-container {
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    animation-duration: 1000ms;
    animation-name: loadingLogo;
    text-align: center;

    .loading-notice {
        font-family: sans-serif;
        white-space: pre-line;
        padding-top: 20px;
        color: red;
    }
}

#adsbox {
    position: absolute;
    top: -500px;
}

@keyframes loadingLogo {
    0% {
        opacity: 0;
        transform: translate(-50%, 100%);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

/* Cookie Consent */

.cc-grower {
    position: relative;
    z-index: 9999;
}

/* UserPilot - hide empty content box for modal videos (theme 8) */

.userpilot-slide[theme_id='8'] {
    display: none !important;
}

body > .osano-expanded {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1150;
}

.hidden {
    display: none;
}

.o-print-body {
    overflow: visible !important;

    .o-print-avoid-break {
        page-break-inside: avoid;
    }

    .o-print-hide,
    [class^='o-print-hide-'],
    [class*=' o-print-hide-'] {
        display: none !important;
    }

    .o-print-ancestor,
    [class^='o-print-ancestor-'],
    [class*=' o-print-ancestor-'] {
        margin: 0 !important;
        padding: 0 !important;
        border: none !important;
        box-shadow: none !important;
    }

    /* Always expand collapsables inside a print */

    .o-print,
    [class^='o-print-'],
    [class*=' o-print-'] {
        .o-print-hide,
        *:not([class^='o-print-hide-']),
        *:not([class*=' o-print-hide-']) {
            .MuiCollapse-root {
                height: auto !important;
                visibility: visible !important;
            }
            .MuiAccordionSummary-content {
                margin-left: 8px;
                &.Mui-expanded {
                    margin-left: 8px;
                }
            }
            .MuiAccordionSummary-expandIconWrapper {
                transform: rotate(90deg);
            }
            .MuiAccordionSummary-root {
                min-height: 64px;
            }
            .MuiAccordion-root {
                box-shadow: none;
                margin: 0;
                &:not(:last-child) {
                    border-bottom: 0;
                }
            }
        }
    }
}

/* stylelint-disable selector-no-vendor-prefix */

input,
textarea,
select {
    filter: none;

    &::placeholder {
        color: #333;
        font-size: 1em !important;
        opacity: 1;
    }
    &::-moz-placeholder,
    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &:-ms-input-placeholder {
        font-size: 1em !important;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        animation-name: autofill !important;
        animation-fill-mode: both !important;
    }
}

@keyframes autofill {
    to {
        color: #666;
        background: transparent;
    }
}

.RichEditor-editor {
    cursor: text;
    font-size: 16px;
    padding: 10px;
    height: 200px;
    overflow: auto;
}

.DraftEditor-editorContainer {
    position: relative;
    font-family: Roboto, sans-serif;
}

.DraftEditor-editorContainer ol li {
    margin-left: 16px;
    list-style-type: decimal;
}

.DraftEditor-editorContainer ul li {
    margin-left: 16px;
    list-style-type: disc;
}

.public-DraftEditorPlaceholder-root {
    position: absolute;
}

.public-DraftEditorPlaceholder-inner {
    color: #ccc;
}

.DraftEditor-root {
    height: unset !important;
}

.RichEditor-hidePlaceholder .DraftEditor-root .public-DraftEditorPlaceholder-root {
    display: none;
}

.align-right div {
    text-align: right;
}

.align-center div {
    text-align: center;
}

.align-left div {
    text-align: left;
}

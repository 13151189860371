.osano-cm-window {
    font-size: 62.5%;
    font-family: Helvetica, Arial, 'Hiragino Sans GB', STXihei, 'Microsoft YaHei',
        'WenQuanYi Micro Hei', Hind, 'MS Gothic', 'Apple SD Gothic Neo', NanumBarunGothic,
        sans-serif;
    display: block;
    z-index: 2147483638;
    line-height: 1.5em;
    width: 100%;

    label {
        margin-bottom: 0;
    }

    * {
        box-sizing: initial;
        outline: 0;
        font-family: Helvetica, Arial, 'Hiragino Sans GB', STXihei, 'Microsoft YaHei',
            'WenQuanYi Micro Hei', Hind, 'MS Gothic', 'Apple SD Gothic Neo', NanumBarunGothic,
            sans-serif;

        > * {
            box-sizing: initial;
            outline: 0;
            font-family: Helvetica, Arial, 'Hiragino Sans GB', STXihei, 'Microsoft YaHei',
                'WenQuanYi Micro Hei', Hind, 'MS Gothic', 'Apple SD Gothic Neo', NanumBarunGothic,
                sans-serif;
        }
    }

    .hidden,
    * .hidden {
        display: none;
    }

    a.osano-cm-link {
        color: #92bffb;
        cursor: pointer;
        text-decoration: underline;

        &:hover,
        &:active {
            color: #92bffb;
        }
    }

    .osano-cm-button {
        background: #fff;
        color: #000;
        border: none;
        box-shadow: 1px 1px 3px rgba(100, 100, 100, 0.7);
        border-radius: 5px;
        font-weight: bold;
        font-size: 1.1em;
        padding: 0.6em 0.8em;
        margin: 2px;
        line-height: 0.8em;
        flex: 1;
        cursor: pointer;
        outline: none;

        &:hover {
            background: #ebebeb;
        }

        &.osano-cm-deny {
            background: #989;
            color: #fff;
        }
    }

    .osano-cm-toggle label {
        cursor: pointer;
        text-indent: -9999px;
        width: 44px;
        height: 22px;
        background: #d2cfff;
        display: block;
        border-radius: 10px;
        position: relative;
        transition: 0.3s;

        &::after {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            width: 18px;
            height: 18px;
            background: white;
            border-radius: 9px;
            transition: 0.3s;

            &:active {
                width: 26px;
                transition: 0.1s;
            }
        }
    }

    ul.osano-cm-info-list {
        list-style-type: none;
        margin: 0 3px;
        padding: 0;

        > li {
            margin: 0;
            padding: 2px 0;

            &:last-child {
                margin-bottom: 14px;
            }

            .osano-cm-title {
                display: flex;
                justify-content: space-between;
            }

            label {
                display: inline-block;
            }

            .osano-cm-label {
                font-size: 1.4em;
                padding-top: 6px;
            }

            .osano-cm-description {
                font-size: 1.1em;
                font-weight: 300;
                line-height: 0.9rem;
                padding: 0.25em 0.25em 0.5em 0.125em;
            }
        }
    }

    .osano-cm-toggle {
        input[type='checkbox'] {
            height: 0;
            width: 0;
            visibility: hidden;

            &:checked {
                + label {
                    background: #37cd8f;

                    &::after {
                        left: calc(100% - 2px);
                        transform: translateX(-100%);
                        background: #f4f4f4;
                    }
                }

                &:disabled + label {
                    background: #1b6a49;

                    &::after {
                        background: #b4b4b4;
                    }
                }
            }
        }
    }

    .osano-cm-dialog {
        font-size: 1.8em;
        line-height: 1.2em;
        align-items: center;
        background: black;
        color: white;
        position: fixed;
        z-index: 2147483637;
        padding: 2em;

        .osano-cm--type_bar & {
            display: flex;
            box-sizing: border-box;
            width: 100%;
            left: 0;
        }

        .osano-cm--type_box & {
            width: 250px;
            flex-direction: column;
            max-width: calc(100vw - 20px);
            max-height: calc(100vh - 48px);
            overflow: auto;
        }

        .osano-cm--type_bar.osano-cm--position_bottom & {
            top: auto;
            bottom: 0;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
        }

        .osano-cm--type_bar.osano-cm--position_top & {
            top: 0;
            bottom: auto;
            box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.9);
        }

        .osano-cm--type_bar.osano-cm--borderless & {
            box-shadow: none;
        }

        .osano-cm--type_box.osano-cm--position_top-left & {
            top: 20px;
            left: 20px;
            right: auto;
            bottom: auto;
        }

        .osano-cm--type_box.osano-cm--position_top-right & {
            top: 20px;
            left: auto;
            right: 20px;
            bottom: auto;
        }

        .osano-cm--type_box.osano-cm--position_bottom-left & {
            bottom: 20px;
            left: 20px;
            right: auto;
            top: auto;
        }

        .osano-cm--type_box.osano-cm--position_bottom-right & {
            bottom: 20px;
            left: auto;
            right: 20px;
            top: auto;
        }

        .osano-cm--type_box.osano-cm--position_center & {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            bottom: unset;
            right: unset;
        }

        .osano-cm-content {
            .osano-cm--type_bar & {
                flex: 5;
            }

            .osano-cm--type_box & {
                display: flex;
                flex-direction: column;
                flex-grow: 0.0001;
                transition: flex-grow 1000ms linear;
            }
        }

        .osano-cm-buttons {
            .osano-cm--type_bar & {
                flex: 1;
                justify-content: flex-end;
                margin-left: 10px;
                display: flex;
                flex-wrap: wrap;
            }

            .osano-cm--type_box & {
                margin: 10px 0 0;
            }
        }

        ul.osano-cm-info-list .osano-cm-label {
            font-size: 1.2em;
        }

        .osano-cm-link {
            display: inline-block;
            padding: 0 2px;
        }

        ul.osano-cm-info-list {
            margin: 8px 0 0;

            .osano-cm--type_bar & {
                display: flex;
                flex-flow: wrap;
            }

            .osano-cm--type_box & {
                width: calc(100% - 10px);
            }

            > li {
                display: flex;

                label.osano-cm-label {
                    font-size: 0.9em;
                    margin-left: 6px;
                    white-space: nowrap;

                    .osano-cm--type_bar & {
                        padding-top: 0;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .osano-cm--type_bar & {
                    margin-left: 6px;

                    &:first-child {
                        margin: 0;
                    }
                }
            }

            .osano-cm-toggle input[type='checkbox'] {
                position: absolute;
            }
        }

        .osano-cm-storage-policy {
            text-transform: capitalize;
        }
    }

    .osano-cm-widget {
        cursor: pointer;
        position: fixed;
        background: none;
        border: none;
        bottom: 12px;
        z-index: 2147483636;
        width: 40px;
        height: 40px;
        opacity: 0.9;
        transition: all 0.1s;

        .osano-cm--widget-position_right & {
            right: 8px;
            left: unset;
        }

        .osano-cm--widget-position_left & {
            left: 8px;
        }

        &:hover {
            opacity: 1;
            transform: scale(1.1);
        }
    }

    .osano-cm-info-dialog-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: #00000075;
        z-index: 2147483638;
    }

    .osano-cm-info-dialog {
        box-sizing: border-box;
        position: fixed;
        top: 0;
        background: white;
        color: black;
        width: 320px;
        height: 100vh;
        transition: all 0.7s;
        padding: 8px 12px;
        box-shadow: 0 0 2px 2px #ccc;
        overflow: auto;

        .osano-cm--info-position_right & {
            right: -320px;
        }

        .osano-cm--info-position_left & {
            left: -320px;
        }

        &.open {
            .osano-cm--info-position_right & {
                right: 0;
            }

            .osano-cm--info-position_left & {
                left: 0;
            }
        }

        header {
            h2 {
                font-size: 1.5em;
                padding: 0.5em 0;
            }

            > p {
                font-size: 1.2em;
            }
        }

        .osano-cm-info-dialog-close {
            cursor: pointer;
            font-size: 1.4em;
            float: right;
            text-decoration: none;
            height: 20px;
            width: 20px;
            transition: all 0.2s ease-out;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            margin: 0;

            &:hover {
                -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
            }
        }

        .osano-cm-save {
            line-height: 1.2em;
            font-weight: 400;
            width: 80%;
            display: inherit;
            margin: 0 auto;
        }

        .osano-cm-powered-by {
            margin-top: 10px;

            .osano-cm-link {
                display: block;
                margin: 0 auto;
                width: fit-content;
            }
        }
    }
}

.osano-cm--type_bar {
    .osano-cm-window {
        @media screen and (max-width: 807px) {
            .osano-cm-dialog {
                flex-direction: column;

                .osano-cm-buttons {
                    width: 100%;
                    margin: 0;

                    button {
                        flex: none;
                        width: 80%;
                        margin: 0 auto;
                    }
                }

                ul.osano-cm-info-list {
                    max-width: 400px;
                    margin: 10px auto;

                    li {
                        min-width: 180px;
                    }
                }
            }
        }

        @media screen and (max-width: 807px) and (min-width: 471px) {
            .osano-cm-dialog ul.osano-cm-info-list li:nth-child(2n) {
                margin-left: 34px;
            }
        }

        @media screen and (max-width: 807px) and (max-width: 471px) {
            .osano-cm-dialog ul.osano-cm-info-list li {
                min-width: 240px;
            }
        }

        @media screen and (max-width: 807px) {
            .osano-cm-dialog ul.osano-cm-info-list li:first-child {
                margin: 0 0 0 6px;
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .osano-cm-window {
        .osano-cm-dialog ul.osano-cm-info-list {
            flex-direction: column;
        }
    }
}

#osano-resource-center {
    align-items: flex-start;
    background-color: transparent;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    left: 0;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    transition-delay: 250ms, 0ms;
    transition-duration: 0ms, 250ms;
    transition-property: visibility, background-color;
    transition-timing-function: linear, ease-in-out;
    visibility: hidden;
}

#osano-resource-center.open {
    transition-delay: 0ms, 0ms;
    visibility: visible;
}

#hubspot-conversations-inline-parent {
    height: 100%;
    transform: translateX(100%);
    transition-delay: 0ms;
    transition-duration: 250ms;
    transition-property: transform;
    transition-timing-function: ease-in-out;
}

#hubspot-conversations-inline-iframe {
    height: 100%;
}
